<template>
  <div class="container">
    <div class="content">
      <!-- 操作区 -->
      <div style="text-align: right">
        <el-button @click="openRoleDialog('add')" type="primary" icon="el-icon-plus">添加角色</el-button>
      </div>

      <div class="file-list">
        <!-- 角色表格 -->
        <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%; margin-top: 10px"
          :data="roleList"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
          <!-- 角色名称 -->
          <el-table-column prop="name" label="角色名称" width="300px"></el-table-column>
          <!-- 描述 -->
          <el-table-column prop="desc" label="描述"></el-table-column>
          <!-- 成员数量 -->
          <el-table-column prop="member_num" label="成员数量">
            <template slot-scope="scope">
              <span @click="$router.push({path: '/member/role/set', query: {name: scope.row.name, id: scope.row.id}})" 
                style="color: #1890FF; cursor: pointer;">{{scope.row.member_num}}</span>
            </template>
          </el-table-column>
          <!-- 编辑/删除 -->
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button style="color: #ff0000" @click="delRole(scope.row)" type="text">删除</el-button>
              <el-button style="color: rgba(0, 0, 0, 0.1)" type="text">|</el-button>
              <el-button @click="openRoleDialog('edit', scope.row)" type="text">更改权限</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 角色编辑 -->
      <el-dialog title="编辑角色" width="800px" custom-class="zg-dialog" center
        :lock-scroll="true"
        :close-on-click-modal="false"
        :visible.sync="roleDialog">
        <div class="custom-scroll-bar dialog-content">
          <el-form ref="roleForm" label-width="80px" label-position="right"
            :model="roleInfo" 
            :rules="roleRules"
            :validate-event="false" >
            <!-- 角色名称 -->
            <el-form-item label="角色名称" prop="name">
              <el-input v-model="roleInfo.name" maxlength="15"></el-input>
            </el-form-item>

            <!-- 角色描述 -->
            <el-form-item label="角色描述" prop="desc">
              <el-input v-model="roleInfo.desc" type="textarea" rows="4" maxlength="1000" show-word-limit></el-input>
            </el-form-item>   

            <!-- 权限管理 -->
            <el-form-item label="权限管理">
              <p>企管后台权限</p>
              <el-tree ref="resource" node-key="id" show-checkbox
                :default-checked-keys="['10000000']"
                :data="allMenu"
                :props="{label: 'name', isLeaf: 'leaf'}"></el-tree>
            </el-form-item>
          </el-form>
        </div>

        <!-- 底部操作按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button plain @click="roleDialog = false">取消</el-button>
          <el-button @click="upadteRole()" type="primary">保存</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {promisify, isObject} from "@/js/Utils"
export default {
  data(){
    return {
      roleList: [], //角色列表
      currentRole: { // 当前选中的角色
        pageIndex: 0,
        pageNumber: 10,
        pageTotal: 0
      },
      roleDialog: false, // 角色编辑弹窗开关
      editModel: "", // add or edit
      roleInfo: { // 当前角色详情
        name: "",
        desc: "",
      },
      resourceIds: [],
      roleRules: { // 表单校验规则
        name: [
          {required: true, message: '角色名称不能为空', trigger: 'blur'},
          {min: 3, max: 15, message: '角色名称在3-15个字符之间', trigger: 'blur'}
        ],
        desc: [
          {required: true, message: '角色描述不能为空', trigger: 'blur'},
          {min: 0, max: 1000, message: '角色描述不能大于1000字', trigger: 'blur'}
        ]
      },
      allMenu: [],
      roleMemberCount: 0,
      defaultPower: [], // 角色的默认权限
    }
  },
  mounted(){
    this.getRoleList();
    this.getResource();
  },
  methods: {
    // 查询角色列表
    async getRoleList(){
      let res = await this.$ZGManager.getRoleList();
      if(res.status == 200 && res.data){
        this.roleList = res.data.map(e => Object.assign({member_num: ''}, e));
        // 根据ID获取角色下的成员数量
        this.roleList.forEach(async (item) => {
          let res = await this.$ZGManager.getMembersByRole(item.id, 1);
          if (res.status == 200) item.member_num = res.data;
        });
      }
    },

    // 打开角色编辑弹窗
    async openRoleDialog(type, item){
      this.editModel = type;
      this.roleDialog = true;
      if(type == 'edit'){
        this.roleInfo = JSON.parse(JSON.stringify(item));
        this.resourceIds = [];
        this.getRoleResource(this.roleInfo.id);
      }else if(type == 'add'){
        this.roleInfo = {name: "", desc: ""};
      }
      this.$nextTick(() => {
        this.$refs.roleForm.clearValidate();
        this.$refs.resource.setCheckedKeys([]);
      });
    },

    // 更新角色信息
    async upadteRole(){
      let getResult = await this.$refs['roleForm'].validate;
      let result = await getResult();
      if(isObject(result)) return this.$message.error("信息填写有误，请检查后重新输入");

      let res;
      let params = this.roleInfo;
      params.resourceIds = [];
      // 获取半选中的节点的key，以及完全选中的节点的key
      let parent = this.$refs.resource.getHalfCheckedNodes();
      let children = this.$refs.resource.getCheckedNodes();
      if(parent.length > 0) parent.map(e => params.resourceIds.push(e.id));
      if(children.length > 0) children.map(e => params.resourceIds.push(e.id));
      
      if(this.editModel == 'add'){
        res = await this.$ZGManager.createRole(params);
      }else if(this.editModel == 'edit'){
        res = await this.$ZGManager.updateRole(params.id, params);
      }
      if(res.status == 200 && res.data){
        this.$message.success("更新成功");
        this.getRoleList();
        this.roleDialog = false;
      }
    },

    // 删除角色
    async delRole(item){
      let dialog = await this.$confirm(`是否要删除 ”${item.name}“`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let res = await this.$ZGManager.delRole(item.id);
      if(res.status == 200 && res.data){
        this.$message.success("删除成功");
        this.getRoleList();
      }
    },

    // 获取全部资源列表
    async getResource(){
      let res = await this.$ZGManager.getResource(0);
      if(res.status == 200 && res.data){
        // 先找到所有的父级元素，给每一项添加children并赋值给allMenu。
        this.allMenu = [];
        res.data.map((e) => {
          if(!e.parentId){
            e.children = [];
            e.leaf = true;
            this.allMenu.push(e);
          }
        });
        // 再循环allMenu，找到与其对应的子节点，并添加对应的children
        this.allMenu.map((e, index) => {
          res.data.map((item) => {
            if(e.id == item.parentId) this.allMenu[index].children.push(item);
          })
        });
      }
    },

    // 根据角色ID获取角色所拥有的的资源列表
    async getRoleResource(id){
      let res = await this.$ZGManager.getRoleResource(id, 0);
      if(res.status == 200){
        res.data.map(e => this.resourceIds.push(e.id));
        this.resourceIds.push("10000000");
        this.$refs.resource.setCheckedKeys(this.resourceIds);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-title{
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 10px;
}
.content{
  background-color: #fff;
  padding: 15px;
}
.dialog-content{
  height: 500px;
  padding-right: 15px;
  overflow-y: auto;
}
</style>